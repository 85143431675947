//#### Page
* {
  box-sizing: border-box;
}
html, body {
  min-height:100%;
  font-family: 'Roboto', sans-serif;
}
#page-navbar, .container-fluid{
  min-width: 1200px;
}
#page-navbar{
  font-family: 'Roboto Condensed', sans-serif;
  padding-left: 0;
  padding-right: 0;
  .container{
    padding-left: 15px;
    padding-right: 15px;
  }
}

.page-logo {
  height: 2rem;
  display: inline-block;
  padding-right: .5rem;
}

.page-logo:last-child {
  padding-right: 0;
}

#page-navbar .navbar-brand {
  margin-right: 0;
  display: flex;
  order: 10;
}

.footer-links {
  color: #009966;
}

//#### Kacheln auf Startseite
