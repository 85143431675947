@import "./inc/variables";
@import "./inc/fonts";
@import "./inc/layout";
@import "./inc/content";
@import "./inc/other";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-select/sass/bootstrap-select";
@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker.css";
@import "~grapesjs/dist/css/grapes.min.css";
@import "~quill/dist/quill.snow.css";
@import "~eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css";
@import "./fontawesome/fontawesome.scss";
@import "./fontawesome/light.scss";
@import "./fontawesome/solid.scss";

a i {
    color: inherit;
}