img{
  max-width: 100%;
  height: auto;
  display: block;
}
.icon {
  height: 1rem;
  width: 1rem;
  display: inline-block;
}
a.disabled{
  cursor: default;
  pointer-events: none;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto Condensed', sans-serif;
}
thead th{
  font-family: 'Roboto Condensed', sans-serif;
}
.btn{
  font-family: 'Roboto Condensed', sans-serif;
  background-color: white;
}
.footer-links{
  font-family: 'Roboto Condensed', sans-serif;
}
label{
  font-family: 'Roboto Condensed', sans-serif;
}
.table{
  .icon{
    height: 1rem;
  }
}
//#### Bootstrap accordion modification
.accordion {
  margin-bottom: 1rem;
  > .card > .card-header {
    padding: 0;
    position: relative;
    button {
      width: 100%;
      font-weight: bold;
      font-size: 1.2rem;
      text-align: left;
    }
  }
  .card-body{
    padding: 1rem;
  }
  .card .card {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    &:not(:first-of-type):not(:last-of-type){
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }
  }
}
.accordion .card-body h1.separator,
.accordion .card-body h2.separator,
.accordion .card-body h3.separator,
.accordion .card-body h4.separator,
.accordion .card-body h5.separator,
.accordion .card-body h6.separator {
  padding: 1rem 1.25rem 0;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  margin-top: 1rem;
  margin-left: -1rem;
  margin-right: -1rem;
}
form .card-header.is-invalid:after {
  content: url(/assets/images/icons/fontawesome/custom/exclamation-triangle-light.svg);
  display: block;
  position: absolute;
  background-color: #c20069;
  color: white;
  top: 0;
  bottom: 0;
  right: 0;
  width: 2.5rem;
  padding: .5rem;
}

//#### Bootstrap form modifications
.form-control, .form-control-plaintext, .btn-bs {
  padding: 1px 0.5rem;
}
.form-control, .form-control-plaintext{
  height: calc(1.5rem + 2px);
  padding: 0 0.5rem;
}
.col-form-label {
  padding-top: 1px;
  padding-bottom: 1px;
}
.form-group {
  margin-bottom: .5rem;
}
select.form-control:not([size]):not([multiple]) {
  height: calc(1.5rem + 2px);
}
@media (min-width: 576px) {
  .col-form-label{
    text-align: right;
  }
}
.form-control.is-invalid{
  border-color: #721c24;
  background-color: #ffeaec;
  color: #721c24;
}
// Highlight for required fields
//input.required,
//textarea.required,
//select.required,
//select.required + .dropdown-toggle,
//select.required + div + .dropdown-toggle,
//select.required + .btn-light.dropdown-toggle,
input:invalid,
textarea:invalid,
select:invalid,
select:invalid + .dropdown-toggle,
select:invalid + div + .dropdown-toggle,
select:invalid + .btn-light.dropdown-toggle
{
  background-color: lightgoldenrodyellow;
  &:focus{
    background-color: lightgoldenrodyellow;
  }
}

input.form-control
{
  height: 38px;
  line-height: 38px;
}

.form-check
{
  //height: 38px;
  //line-height: 38px;
  padding-left: 0 !important;
}

input.form-check-input
{
  //height: 38px;
  //line-height: 38px;
  margin-top: 0;
}

.card
{
  transition: border .15s ease-in-out;
}

.selector:hover > .card
{
  border-color: $primary;
}
.selector input:checked + .card
{
  border-color: $primary;
}

// Spacing for rows with background color
.row.is-bg,
.form-row.is-bg{
  margin-left: 0;
  margin-right: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
.form-row.is-bg{
  padding-top: 5px;
  padding-bottom: 5px;
}
.btn--svg{
  line-height: 1;
  svg{
    width: 1rem;
    height: 1rem;
    fill: #fff;
  }
}
.card-header{
  font-family: 'Roboto Condensed', sans-serif;
}
.active-permissions__row .card-header{
  font-weight: bold;
}

.badge-outline-warning{
  border: 1px solid $color-secondary;
  color: $color-secondary;
  vertical-align: text-top;
}

.badge-outline-danger{
  border: 1px solid $form-feedback-invalid-color;
  color: $form-feedback-invalid-color;
  vertical-align: text-top;
}

.text-decoration-none {
  text-decoration: none;
}

// GJS

.gjs-four-color {
  color: $color-primary !important;
}

.gjs-four-color-h:hover {
  color: $color-primary !important;
}

// Parsley

.parsley-errors-list {
  position: absolute;
  bottom: 1.7rem;
  right: 5px;
  max-width: 320px;
}

.parsley-errors-list li {
  list-style: none;
  font-size: .7rem;
  color: $form-feedback-invalid-color;
}

.nav-link.parsley-error {
  color: $form-feedback-invalid-color;
}


