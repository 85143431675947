/* roboto-italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('../../fonts/roboto/roboto-v18-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Italic'), local('Roboto-Italic'),
  url('../../fonts/roboto/roboto-v18-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/roboto/roboto-v18-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/roboto/roboto-v18-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/roboto/roboto-v18-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/roboto/roboto-v18-latin-italic.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/roboto/roboto-v18-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
  url('../../fonts/roboto/roboto-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/roboto/roboto-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/roboto/roboto-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/roboto/roboto-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/roboto/roboto-v18-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/roboto/roboto-v18-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold'), local('Roboto-Bold'),
  url('../../fonts/roboto/roboto-v18-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/roboto/roboto-v18-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/roboto/roboto-v18-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/roboto/roboto-v18-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/roboto/roboto-v18-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-700italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url('../../fonts/roboto/roboto-v18-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
  url('../../fonts/roboto/roboto-v18-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/roboto/roboto-v18-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/roboto/roboto-v18-latin-700italic.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/roboto/roboto-v18-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/roboto/roboto-v18-latin-700italic.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-condensed-regular - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/roboto-condensed/roboto-condensed-v16-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto Condensed'), local('RobotoCondensed-Regular'),
  url('../../fonts/roboto-condensed/roboto-condensed-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/roboto-condensed/roboto-condensed-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/roboto-condensed/roboto-condensed-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/roboto-condensed/roboto-condensed-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/roboto-condensed/roboto-condensed-v16-latin-regular.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}

/* roboto-condensed-italic - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 400;
  src: url('../../fonts/roboto-condensed/roboto-condensed-v16-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Condensed Italic'), local('RobotoCondensed-Italic'),
  url('../../fonts/roboto-condensed/roboto-condensed-v16-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/roboto-condensed/roboto-condensed-v16-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/roboto-condensed/roboto-condensed-v16-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/roboto-condensed/roboto-condensed-v16-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/roboto-condensed/roboto-condensed-v16-latin-italic.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}

/* roboto-condensed-700 - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/roboto-condensed/roboto-condensed-v16-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'),
  url('../../fonts/roboto-condensed/roboto-condensed-v16-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/roboto-condensed/roboto-condensed-v16-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/roboto-condensed/roboto-condensed-v16-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/roboto-condensed/roboto-condensed-v16-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/roboto-condensed/roboto-condensed-v16-latin-700.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}

/* roboto-condensed-700italic - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 700;
  src: url('../../fonts/roboto-condensed/roboto-condensed-v16-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Condensed Bold Italic'), local('RobotoCondensed-BoldItalic'),
  url('../../fonts/roboto-condensed/roboto-condensed-v16-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/roboto-condensed/roboto-condensed-v16-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/roboto-condensed/roboto-condensed-v16-latin-700italic.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/roboto-condensed/roboto-condensed-v16-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/roboto-condensed/roboto-condensed-v16-latin-700italic.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}

.ico:before {
  content: '';
  display: block;
  font-size: 5em;
  text-rendering: auto;
  background-position: center center;
  background-size: 60%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.ico-link:before {
  background-image: url("/assets/images/icons/fontawesome/light/link.svg");
}

.ico-quote-right:before {
  background-image: url("/assets/images/icons/fontawesome/light/quote-right.svg");
}

.ico-image:before {
  background-image: url("/assets/images/icons/fontawesome/light/image.svg");
}

.ico-text:before {
  background-image: url("/assets/images/icons/fontawesome/light/text.svg");
}

.ico-divider:before {
  background-image: url("/assets/images/icons/fontawesome/light/horizontal-rule.svg");
}

.fa-trash-o::before {
  content: "\f1f8";
}