.disabled {
  cursor: not-allowed;
}

label.btn.bg-light:hover {
  background-color: #dae0e5 !important;
}

.w-1{
  width: 1px;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default !important;
}

.overflow-auto
{
  overflow: auto;
}

.log-container
{
  max-height: 512px;
}

.spin
{
  animation-name: spin;
  animation-iteration-count: infinite;
  animation-duration: .5s;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

.icon-xl
{
  height: 1.5rem;
  width: 1.5rem;
  display: inline-block;
}

.icon-sm
{
  height: .75rem;
  width: .75rem;
  display: inline-block;
}

.v-align-base
{
  vertical-align: baseline;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #f8f9fa !important;
  opacity: 1;
  border-color: #f8f9fa;
  cursor: not-allowed;
}


.bootstrap-select .dropdown-menu li.selected.active a:hover {
  color: #fff !important;
}