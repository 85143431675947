$color-primary: #3f5bf5;
$color-secondary: #272838;
//#### Bootstrap modifications
$primary: $color-primary;
$secondary: $color-secondary;
$form-feedback-valid-color: $color-primary;
$form-feedback-invalid-color: #d53342;
$tooltip-arrow-color: $color-secondary;
$tooltip-bg: $color-secondary;

@import "./main.scss";